import router from '@/router';
import store from '@/store/store';

export function errorHandler(error) {
    if (error.response) {
        if (error.response.status === 401 || error.response.status === 403) {
            store.dispatch('tryAutoLogin');
            if (store.getters.estaAutenticado) {
                router.push('/naoautorizado');
            } else {
                router.push('/login');
            }
        } else if (error.response.status === 400 || error.response.status === 404) {
            let errorData = error.response.data;
            if (error.response.config.responseType == 'blob') {
                return ['Erro ao obter arquivo ou arquivo inexistente.'];
            } else if (errorData.exception) {
                store.dispatch('setRequestError', errorData.message);
                router.push('/error');
            } else {
                return [...errorData.errors.mensagens];
            }
        } else {
            store.dispatch('setRequestError', error.response);
            router.push('/error');
        }
    } else if (error.request) {
        store.dispatch('setRequestError', error.request);
        router.push('/error');
    }
}
