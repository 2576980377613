<template>
    <div class="grid">
        <div class="col"></div>
        <div class="col-8 md:col-5">
            <qrcode-stream :camera="camera" @decode="onDecode"></qrcode-stream>
        </div>
        <div class="col"></div>
    </div>
    <erros-box :erros="erros"></erros-box>
</template>

<script>
import { QrcodeStream } from 'vue3-qrcode-reader';
import Services from './services';

export default {
    data() {
        return {
            camera: 'auto',
            processando: false,
        };
    },
    components: {
        QrcodeStream,
    },
    methods: {
        onDecode(decodedString) {
            this.salvarCheckIn(decodedString);
        },

        limpar() {
            this.camera = this.camera == 'auto' ? 'rear' : 'auto';
        },

        salvarCheckIn(dados) {
            this.$store.dispatch('addRequest');
            this.processando = true;
            let dadosDto = {
                dadosLidos: dados,
            };
            Services.inserir(dadosDto).then((response) => {
                if (response?.success) {
                    this.$swal({
                        title: 'CheckIn QRCode',
                        html: `Realizado com sucesso!!!`,
                        icon: 'success',
                        timer: 2000,
                        timerProgressBar: true,
                        showConfirmButton: false,
                    });
                } else {
                    this.erros = response.errors;
                }
                this.processando = false;
                this.$store.dispatch('removeRequest');
            });
        },
    },
};
</script>
